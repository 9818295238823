<template>
    <AppPanel :location="[title]" subtitle="Preencha os campos abaixo" :hasBackButton="true" backPath="/riscos">
        <template #content>
            <AppFormCadastro
                :service="service"
                :form="form"
                backPath="/riscos"
                @onBeforeSave="onBeforeSave"
                @onAfterSave="onAfterSave"
                @onLoadDataEdit="onLoadDataEdit"
                @onValidate="onValidate"
            >
                <template #content>
                    <div class="flex flex-row gap-2 align-items-center text-800 mb-1">
                        <AppInfoManual nomeTelaDoManual="riscos-form" />
                        <h3 class="ml-1">{{ title }}</h3>
                    </div>
                    <div class="p-fluid painel painel-2">
                        <TabView :scrollable="true" :activeIndex="activeIndex" @tab-click="onTabClick">
                            <TabPanel header="Geral">
                                <div class="grid formgrid">
                                    <div class="field col-6">
                                        <label>Nome</label>
                                        <InputText
                                            id="nome"
                                            v-model.trim="form.nome"
                                            minlength="2"
                                            maxlength="100"
                                            autocomplete="off"
                                            :class="{ 'p-invalid': submitted && !form.nome }"
                                        />
                                        <small class="p-error" v-if="submitted && !form.nome">Nome é obrigatório.</small>
                                    </div>
                                    <div class="field col-6">
                                        <label for="gruposRiscos">Grupo</label>
                                        <Dropdown
                                            id="gruposRiscos"
                                            v-model="form.grupoRisco"
                                            :options="gruposRiscos"
                                            optionLabel="label"
                                            optionValue="value"
                                            placeholder="Selecione..."
                                            :class="{ 'p-invalid': submitted && !form.grupoRisco }"
                                        />
                                        <small class="p-error" v-if="submitted && !form.grupoRisco">Grupo é obrigatório.</small>
                                    </div>
                                    <div class="field col-12 field-checkbox">
                                        <InputSwitch id="checklist" v-model="form.checklist" />
                                        <label for="checklist" class="m-2 ml-2">Utilizar no levantamento</label>
                                    </div>
                                    <h5 class="mb-4">eSocial</h5>
                                    <div class="field col-12 field-checkbox">
                                        <InputSwitch id="envEsocial" v-model="form.envEsocial" />
                                        <label for="envEsocial" class="m-2 ml-2">Envia eSocial</label>
                                    </div>
                                    <div class="field col-12">
                                        <label for="agenteNocivo">Agente Nocivo</label>
                                        <DropdownAgentesNocivos
                                            id="agenteNocivo"
                                            v-model="form.agenteNocivo"
                                            :idValue="form.idAgenteNocivo"
                                            class="w-full"
                                            :class="{ 'p-invalid': submitted && form.envEsocial }"
                                        />
                                        <small class="p-error" v-if="submitted && form.envEsocial">Agente nocivo é obrigatório.</small>
                                    </div>
                                    <div class="field col-4">
                                        <label for="agenteNocivo">
                                            <span class="text-primary pi pi-info-circle" v-tooltip="tooltip" v-if="tooltip"></span> Descrição do
                                            Agente Nocivo
                                        </label>
                                        <Textarea
                                            :autoResize="true"
                                            rows="1"
                                            v-model.trim="form.descricao"
                                            maxlength="999"
                                            autocomplete="off"
                                            :class="{ 'p-invalid': submitted && validarDescricaoRiscoVazia }"
                                        />
                                        <small class="p-error" v-if="submitted && validarDescricaoRiscoVazia"
                                            >Descrição do risco é obrigatório.</small
                                        >
                                    </div>
                                    <div class="field col-4">
                                        <label for="trajetoria">Trajetória (Meio de propagação)</label>
                                        <Dropdown
                                            id="trajetoria"
                                            v-model="form.trajetoria"
                                            :options="trajetorias"
                                            optionLabel="label"
                                            optionValue="value"
                                            placeholder="Selecione..."
                                            :class="{ 'p-invalid': submitted && !form.trajetoria }"
                                        />
                                        <small class="p-error" v-if="submitted && !form.trajetoria">Trajetória é obrigatório.</small>
                                    </div>
                                    <div class="field col-4">
                                        <label for="class_risco">Tipo de Avaliação</label>
                                        <Dropdown
                                            id="class_risco"
                                            v-model="form.tipoAvaliacao"
                                            :options="tiposAvaliacoes"
                                            optionLabel="label"
                                            optionValue="value"
                                            placeholder="Selecione..."
                                            :class="{ 'p-invalid': submitted && !form.tipoAvaliacao && form.agenteNocivo !== '09.01.001' }"
                                        />
                                        <small class="p-error" v-if="submitted && !form.tipoAvaliacao && form.agenteNocivo !== '09.01.001'"
                                            >Tipo de avaliação é obrigatório.</small
                                        >
                                    </div>
                                    <div v-if="validaTipoAvaliacaoFaixaDeConforto" class="field col-3">
                                        <label for="faixaConfortoMinima">Faixa de conforto mínima</label>
                                        <InputNumber
                                            id="faixaConfortoMinima"
                                            v-model="form.faixaConfortoMinima"
                                            :min="0"
                                            placeholder="Informe..."
                                            showButtons
                                            :class="{ 'p-invalid': submitted && validarFaixaConfortoMinimaVazia }"
                                        />
                                        <small class="p-error" v-if="submitted && validarFaixaConfortoMinimaVazia"
                                            >Faixa de conforto mínima é obrigatório.</small
                                        >
                                    </div>
                                    <div v-if="validaTipoAvaliacaoFaixaDeConforto" class="field col-3">
                                        <label for="faixaConfortoMaxima">Faixa de conforto máxima</label>
                                        <InputNumber
                                            id="faixaConfortoMaxima"
                                            v-model="form.faixaConfortoMaxima"
                                            :min="0"
                                            placeholder="Informe..."
                                            showButtons
                                            :class="{ 'p-invalid': submitted && validarFaixaConfortoMaximaVazia }"
                                        />
                                        <small class="p-error" v-if="submitted && validarFaixaConfortoMaximaVazia"
                                            >Faixa de conforto máxima é obrigatório.</small
                                        >
                                    </div>
                                    <div v-if="validaTipoAvaliacaoCriterioQuantitativo" class="field col-3">
                                        <label for="unidadeMedida">Unidade de Medida</label>
                                        <DropdownUnidadesMedida
                                            id="unidadeMedida"
                                            v-model="form.unidadeMedida"
                                            :idValue="form.idUnidadeMedida"
                                            class="w-full"
                                            :class="{
                                                'p-invalid':
                                                    submitted &&
                                                    form.tipoAvaliacao === enumTipoAvaliacaoRisco.CRITERIO_QUANTITATIVO &&
                                                    !form.unidadeMedida?.id
                                            }"
                                        />
                                        <small
                                            class="p-error"
                                            v-if="
                                                submitted &&
                                                form.tipoAvaliacao === enumTipoAvaliacaoRisco.CRITERIO_QUANTITATIVO &&
                                                !form.unidadeMedida?.id
                                            "
                                            >Unidade de medida é obrigatório.</small
                                        >
                                    </div>
                                    <div v-if="validaTipoAvaliacaoCriterioQuantitativo" class="field col-3">
                                        <label for="limiteTolerancia">Limite de Tolerância</label>
                                        <InputNumber
                                            id="limiteTolerancia"
                                            v-model="form.limiteTolerancia"
                                            mode="decimal"
                                            :minFractionDigits="2"
                                            :maxFractionDigits="2"
                                            placeholder="Informe..."
                                            showButtons
                                            :class="{ 'p-invalid': submitted && validarLimiteToleranciaVazia }"
                                        />
                                        <small class="p-error" v-if="submitted && validarLimiteToleranciaVazia"
                                            >Limite de tolerância é obrigatório.</small
                                        >
                                    </div>
                                    <div v-if="validaTipoAvaliacaoCriterioQuantitativo" class="field col-3">
                                        <label for="nivelAcao">Nível de ação</label>
                                        <InputNumber
                                            id="nivelAcao"
                                            v-model="form.nivelAcao"
                                            mode="decimal"
                                            :minFractionDigits="2"
                                            :maxFractionDigits="2"
                                            placeholder="Informe..."
                                            showButtons
                                            :class="{
                                                'p-invalid':
                                                    submitted &&
                                                    form.tipoAvaliacao === enumTipoAvaliacaoRisco.CRITERIO_QUANTITATIVO &&
                                                    form.nivelAcao == null
                                            }"
                                        />
                                        <small
                                            class="p-error"
                                            v-if="
                                                submitted &&
                                                form.tipoAvaliacao === enumTipoAvaliacaoRisco.CRITERIO_QUANTITATIVO &&
                                                form.nivelAcao == null
                                            "
                                            >Nível de ação é obrigatório.</small
                                        >
                                    </div>
                                    <div class="field col-3">
                                        <label for="renovacaoMedicao">Renovação da medição (em meses)</label>
                                        <InputNumber
                                            id="renovacaoMedicao"
                                            v-model="form.renovacaoMedicao"
                                            :min="0"
                                            placeholder="Informe..."
                                            showButtons
                                        />
                                    </div>
                                    <div v-if="validaGrupoQuimico" class="field col-3">
                                        <label for="numeroCas">Número CAS</label>
                                        <InputText
                                            id="numeroCas"
                                            v-model="form.numeroCas"
                                            placeholder="Informe..."
                                            :class="{ 'p-invalid': submitted && form.grupoRisco === enumGrupoRiscos.QUIMICO && !form.numeroCas }"
                                        />
                                        <small class="p-error" v-if="submitted && form.grupoRisco === enumGrupoRiscos.QUIMICO && !form.numeroCas"
                                            >Número CAS é obrigatório.</small
                                        >
                                    </div>
                                    <div class="field col-3"></div>
                                </div>
                                <div
                                    v-if="validaGrupoQuimico && form.tipoAvaliacao === enumTipoAvaliacaoRisco.CRITERIO_QUANTITATIVO"
                                    class="field col-3 field field-checkbox"
                                >
                                    <InputSwitch id="temValorTeto" v-model="form.temValorTeto" />
                                    <label for="temValorTeto" class="m-2 ml-2">Tem valor teto?</label>
                                </div>
                                <div v-if="validaTipoAvaliacaoCriterioQuantitativoEGrupoQuimico" class="field col-3">
                                    <label for="valorTeto">Valor teto</label>
                                    <InputNumber
                                        id="valorTeto"
                                        v-model="form.valorTeto"
                                        :disabled="!form.temValorTeto"
                                        mode="decimal"
                                        :minFractionDigits="2"
                                        :maxFractionDigits="2"
                                        placeholder="Informe..."
                                        showButtons
                                        :class="{ 'p-invalid': submitted && validarValorTetoVazio }"
                                    />
                                    <small class="p-error" v-if="submitted && validarValorTetoVazio">Valor teto é obrigatório.</small>
                                </div>
                            </TabPanel>
                            <TabPanel header="Equipamentos">
                                <EquipamentosGrid :form="form" />
                            </TabPanel>
                            <TabPanel header="Medição" />
                            <TabPanel header="Procedimentos" />
                            <TabPanel header="Descrição de risco">
                                <DescricaoRiscoGrid :form="form" />
                            </TabPanel>
                        </TabView>
                    </div>
                </template>
            </AppFormCadastro>
        </template>
    </AppPanel>
    <!-- <div class="grid">
    <div class="col-12 p-fluid">

          <div class="">
            <div>
              <DataTable class="editable-cells-table mt-3" :value="medicaoRisco" dataKey="label" showGridlines
                          editMode="row" v-model:editingRows="editingRows"  @row-edit-save="onRowEditSave"
                          responsiveLayout="scroll">
                <template #header>Medição </template>

                  <Column field="label" header="">

                  </Column>

                  <Column field="Normativa" header="Normativa">
                    <template #editor="">
                        <Dropdown id="tipo_medicao" v-model.trim="medicaoRiscoNormativa" :options="decretoRisco" optionLabel="label" optionValue="value" autofocus autocomplete="off"
                                :class="{ 'p-invalid': submitted && !form.decretoRisco }" />

                    </template>
                  </Column>

                  <Column field="ruido" header="Ruído">
                    <template #editor="">
                      <Dropdown id="ruido" v-model.trim="medicaoRiscoRuido" :options="decretoRisco" optionLabel="label" optionValue="value" autofocus autocomplete="off"
                                :class="{ 'p-invalid': submitted && !form.decretoRisco }" />
                    </template>
                  </Column>

                  <Column field="nbr" header="NBR ISO - CIE - 8995-1:2013 - NHO-11" style="width:15%">
                    <template #editor="">
                      <InputSwitch  id="nbr" v-model="form.medicaoRiscoBanco" ></InputSwitch>
                    </template>
                  </Column>

                  <Column field="ibutg" header="IBUTG">
                    <template #editor="">
                      <InputSwitch  id="ibutg" v-model="form.medicaoRiscoBanco" ></InputSwitch>
                    </template>
                  </Column>

                  <Column field="limite_variavel" header="Limite variável">
                    <template #editor="">
                      <InputSwitch  id="limite_variavel" v-model="form.medicaoRiscoBanco" ></InputSwitch>
                    </template></Column>

                  <Column field="silica" header="Sílica">
                    <template #editor="">
                      <InputSwitch  id="silica" v-model="form.medicaoRiscoBanco" ></InputSwitch>
                    </template></Column>

                  <Column :rowEditor="true" style="width:10%; min-width:8rem" bodyStyle="text-align:center"></Column>
              </DataTable>
            </div>

            <div class="flex mt-5">
              <Button label="Salvar" icon="pi pi-check" class="p-button-primary w-6" @click="save" />
              <Button label="Voltar" icon="pi pi-times" class="p-button-text w-3 ml-auto" @click="$router.push('/risk')" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
</template>

<script>
import BaseService from '@/services/BaseService';
import { getCurrentCompany } from '@/services/store';
import GrupoRiscos from '../../enums/GrupoRiscos';
import TipoAvaliacaoRisco from '../../enums/TipoAvaliacaoRisco';
import TrajetoriaRisco from '../../enums/TrajetoriaRisco';
import ParecerRisco from '../../enums/ParecerRisco';
import MedicaoRisco from '../../enums/MedicaoRisco';
import DecretoRisco from '../../enums/DecretoRisco';
import SecurityService from '../../services/SecurityService';
import DropdownUnidadesMedida from './components/DropdownUnidadesMedida';
import DropdownAgentesNocivos from './components/DropdownAgentesNocivos';
import EquipamentosGrid from './components/EquipamentosGrid';
import DescricaoRiscoGrid from './components/DescricaoRiscoGrid.vue';
import AppInfoManual from '../../components/AppInfoManual.vue';

export default {
    components: {
        DropdownUnidadesMedida,
        DropdownAgentesNocivos,
        EquipamentosGrid,
        DescricaoRiscoGrid,
        AppInfoManual
    },
    data() {
        return {
            service: null,
            serviceRiscosEquipamentos: null,
            activeIndex: 0,
            enumGrupoRiscos: GrupoRiscos,
            enumTipoAvaliacaoRisco: TipoAvaliacaoRisco,
            enumTrajetoriaRisco: TrajetoriaRisco,
            gruposRiscos: [
                { label: 'Físico', value: GrupoRiscos.FISICO },
                { label: 'Químico', value: GrupoRiscos.QUIMICO },
                { label: 'Biológico', value: GrupoRiscos.BIOLOGICO },
                { label: 'Ergonômico', value: GrupoRiscos.ERGONOMICO },
                { label: 'Acidente/Mecânico', value: GrupoRiscos.ACIDENTE_MECANICO },
                { label: 'Outros', value: GrupoRiscos.OUTRO }
            ],
            trajetorias: [
                { label: 'Pelo ar', value: TrajetoriaRisco.PELO_AR },
                { label: 'Por contato', value: TrajetoriaRisco.POR_CONTATO },
                { label: 'Pelo ar e por contato', value: TrajetoriaRisco.PELO_AR_E_POR_CONTATO },
                { label: 'Vias respiratórias', value: TrajetoriaRisco.VIAS_RESPIRATORIAS },
                { label: 'Via dermal', value: TrajetoriaRisco.VIA_DERMAL },
                { label: 'Vias respiratórias e via dermal', value: TrajetoriaRisco.VIAS_RESPIRATORIAS_E_VIA_DERMAL }
            ],
            tiposAvaliacoes: [
                { label: 'Critério quantitativo', value: TipoAvaliacaoRisco.CRITERIO_QUANTITATIVO },
                { label: 'Critério qualitativo', value: TipoAvaliacaoRisco.CRITERIO_QUALITATIVO },
                { label: 'Faixa de conforto', value: TipoAvaliacaoRisco.FAIXA_DE_CONFORTO }
            ],
            parecerRisco: [],
            idEquip: [],
            decretoRisco: [],
            editingRows: [],
            medicaoRisco: [],
            medicaoRiscoRuido: [],
            medicaoRiscoNormativa: [],
            allEquip: null,
            form: {
                envEsocial: false,
                temValorTeto: false
            },
            submitted: false,
            loading: false
        };
    },

    created() {
        this.service = new BaseService('/riscos');
    },

    async mounted() {
        this.loading = true;
        this.$serviceEquipament = new SecurityService('/tools/equip');
        try {
            this.groupOptions();
            //   if (this.$route.params.id) {
            //     const { data } = await this.$service.findById(this.$route.params.id)
            //     this.form = data
            //   }
        } catch (error) {
            this.loading = false;
            this.$toast.add({
                severity: 'error',
                summary: 'Problemas ao carregar informações!',
                detail: error.message,
                life: 3000
            });
        }
        this.loading = false;
    },
    computed: {
        title() {
            return this.$route.params.id ? 'Editando Perigo/Fator de Risco' : 'Adicionando Perigo/Fator de Risco';
        },
        tooltip() {
            return 'Preenchimento obrigatório se o código de agente nocivo for 01.01.001, 01.02.001, 01.03.001, 01.04.001, 01.05.001, 01.06.001, 01.07.001, 01.08.001, 01.09.001, 01.10.001, 01.12.001, 01.13.001, 01.14.001, 01.15.001, 01.16.001, 01.17.001, 01.18.001 ou 05.01.001.';
        },
        getCurrentCompany() {
            return getCurrentCompany();
        },
        validarDescricaoRiscoVazia() {
            if (this.form.envEsocial && !this.form.descricao) return true;

            return false;
        },
        validarFaixaConfortoMinimaVazia() {
            if (this.form.tipoAvaliacao === this.enumTipoAvaliacaoRisco.FAIXA_DE_CONFORTO && this.form.faixaConfortoMinima == null) return true;

            return false;
        },
        validarFaixaConfortoMaximaVazia() {
            if (this.form.tipoAvaliacao === this.enumTipoAvaliacaoRisco.FAIXA_DE_CONFORTO && this.form.faixaConfortoMaxima == null) return true;

            return false;
        },
        validarLimiteToleranciaVazia() {
            const agentesNocivos = ['01.18.001', '02.01.014'];
            const hasAgentesNocivosParaLimiteTolerancia = agentesNocivos.includes(this.form?.agenteNocivo?.codigo);

            if (
                this.form.tipoAvaliacao === this.enumTipoAvaliacaoRisco.CRITERIO_QUANTITATIVO &&
                this.form.limiteTolerancia == null &&
                hasAgentesNocivosParaLimiteTolerancia
            )
                return true;

            return false;
        },
        validaTipoAvaliacaoFaixaDeConforto() {
            return this.form?.tipoAvaliacao == this.enumTipoAvaliacaoRisco.FAIXA_DE_CONFORTO;
        },
        validaTipoAvaliacaoCriterioQuantitativo() {
            return this.form?.tipoAvaliacao == this.enumTipoAvaliacaoRisco.CRITERIO_QUANTITATIVO;
        },
        validaTipoAvaliacaoCriterioQuantitativoEGrupoQuimico() {
            return (
                this.form?.tipoAvaliacao == this.enumTipoAvaliacaoRisco.CRITERIO_QUANTITATIVO &&
                this.form?.grupoRisco == this.enumGrupoRiscos.QUIMICO &&
                this.form?.temValorTeto
            );
        },
        validaGrupoQuimico() {
            return this.form?.grupoRisco == this.enumGrupoRiscos.QUIMICO;
        },

        validarValorTetoVazio() {
            if (
                this.form.tipoAvaliacao === this.enumTipoAvaliacaoRisco.CRITERIO_QUANTITATIVO &&
                this.form.temValorTeto &&
                this.form.valorTeto == null
            ) {
                return true;
            }

            return false;
        }
    },
    watch: {
        getCurrentCompany(newValue, oldValue) {
            if (newValue !== oldValue) {
                alert('Não é possível trocar a empresa no fluxo de criação/edição de um cadastro!');
                this.$router.push({ path: 'list' });
            }
        },
        'form.agenteNocivo'() {
            this.form.idAgenteNocivo = this.form?.agenteNocivo?.id;
        },
        'form.unidadeMedida'() {
            this.form.idUnidadeMedida = this.form?.unidadeMedida?.id;
        }
    },
    methods: {
        onTabClick(event) {
            this.activeIndex = event.index;
        },
        async onLoadDataEdit(data) {
            this.form = data;
        },
        async onBeforeSave() {
            this.submitted = true;
        },
        async onAfterSave() {
            this.submitted = false;
        },
        groupOptions() {
            this.$serviceEquip = new SecurityService('/tools/equip');

            this.parecerRisco = Object.keys(ParecerRisco).map(function (type) {
                return { label: `${ParecerRisco[type]}`, value: `${type}` };
            });

            this.medicaoRisco = Object.keys(MedicaoRisco).map(function (type) {
                return { label: `${MedicaoRisco[type]}`, value: `${type}` };
            });

            this.decretoRisco = Object.keys(DecretoRisco).map(function (type) {
                return { label: `${DecretoRisco[type]}`, value: `${type}` };
            });
        },

        async getEquip() {
            try {
                const { data } = await this.$serviceEquipament.findAll();
                const equipItem = data?.items?.map((v) => {
                    if (v) {
                        return {
                            value: v?.id,
                            label: v?.name
                        };
                    }
                });
                this.idEquip = equipItem;
            } catch {
                this.$toast.add({ severity: 'error', summary: 'Problemas ao listar equipamentos!', life: 3000 });
            }
        },

        async onValidate(cbSuccess, cbError) {
            const error = this.hasError();
            if (error) {
                this.$toast.add({ severity: 'error', summary: `Há campos obrigatórios não preenchidos: ${error}.`, life: 3000 });
                return cbError();
            }

            return cbSuccess();
        },
        hasError() {
            if (!this.form.nome) return 'Nome';
            if (!this.form.grupoRisco) return 'Grupo do risco';
            if (this.form.envEsocial && !this.form.idAgenteNocivo) return 'Agente nocivo';
            if (this.validarDescricaoRiscoVazia) return 'Descrição do risco';
            if (!this.form.trajetoria) return 'Trajetória';
            if (!this.form.tipoAvaliacao && this.form.agenteNocivo !== '09.01.001') return 'Tipo de avaliação';
            if (this.validarFaixaConfortoMinimaVazia) return 'Faixa de conforto mínima';
            if (this.validarFaixaConfortoMaximaVazia) return 'Faixa de conforto máxima';
            if (this.form.tipoAvaliacao === this.enumTipoAvaliacaoRisco.CRITERIO_QUANTITATIVO && !this.form.unidadeMedida?.id)
                return 'Unidade de medida';
            if (this.validarLimiteTolerancia) return 'Limite de tolerância';
            if (this.form.tipoAvaliacao === this.enumTipoAvaliacaoRisco.CRITERIO_QUANTITATIVO && this.form.nivelAcao == null) return 'Nível de ação';
            if (this.validarValorTetoVazio) return 'Valor teto';
            if (this.form.grupoRisco === this.enumGrupoRiscos.QUIMICO && !this.form.numeroCas) return 'Número CAS';
        }
    }
};
</script>
