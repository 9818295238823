<template>
    <AppPaginatedGridNested
        ref="gridRiscosEquipamentos"
        :service="serviceRiscosEquipamentos"
        parentName="risco"
        :defaultProps="{ idRisco: form?.id }"
        :expander="false"
        labelBotaoAdicionarItem="Equipamento"
        :isValid="isValid"
        :queryParams="queryParams"
        @onValidate="onValidate"
    >
        <template #header>
            <h5>{{ `RISCO: ${form?.nome?.toUpperCase()}` }}</h5>
        </template>
        <template #columns>
            <Column field="equipamento" header="Equipamento">
                <template #editor="{ data, field }">
                    <DropdownEquipamentos v-model="data[field]" @update:modelValue="data.idEquipamento = data[field]?.id" />
                </template>
                <template #body="slotProps">
                    {{ slotProps?.data?.equipamento?.name }}
                </template>
            </Column>
            <Column field="createdAt" :sortable="true" sortField="created_at" header="Criado">
                <template #body="slotProps">
                    {{ $filters.formatDate(slotProps.createdAt) }}
                </template>
            </Column>
        </template>
    </AppPaginatedGridNested>
</template>

<script>
import DropdownEquipamentos from '../../equipment/components/DropdownEquipamentos';
import NestedService from '@/services/NestedService';

export default {
    props: {
        form: {
            type: Object,
            required: true
        }
    },
    components: {
        DropdownEquipamentos
    },
    data() {
        return {
            isValid: true,
        }
    },
    created() {
        this.serviceRiscosEquipamentos = new NestedService('/riscos-equipamentos');
    },
    computed: {
        queryParams() {
            return {
                idRisco: this.$route.params.id
            }
        }
    },
    methods: {
        onValidate(data) {
            if (!data?.idEquipamento) {
                this.$toast.add({
                    severity: 'error',
                    summary: 'Informe o equipamento à ser vinculado!',
                    life: 3000
                });
                this.isValid = false;
            }
            this.isValid = true;
        }
    }
};
</script>

<style lang="scss">
</style>